import DonateBtn from "./DonateBtn";
import RegisterBtn from "./RegisterBtn";

export default function NavButtons() {
  return (
    <>
      <RegisterBtn BigBtn="true"></RegisterBtn>
      <DonateBtn></DonateBtn>
    </>
  );
}
